/**
 * jQuery mCycle 20180927
 *
 * Based on tCycle 20131130
 *
 * @author Kiril Reznik
 * @license MIT/GPL
 */
(function($, window) {
  $.fn.mcycle = function(userOptions) {
    return this.each(function() {
      /*
       Options
       */
      const $container = $(this);
      const containerCSS = {
        position: 'relative',
        overflow: 'hidden'
      };

      const $slides = $container.children();
      const slidesCount = $slides.length;
      const slidesCSS = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0
      };

      const options = $.extend(
          {
            fx: 'fadeOutIn',
            speed: 375,
            timeout: 4500, // 375 * 12
            touch: false,
            pager: ''
          },
          userOptions || {},
          $container.data());

      const effect = options.fx;
      const effectSpeed = options.speed;
      const touch = options.touch;
      const $pagerElement = $(options.pager);

      let timeout = options.timeout;
      let timeoutID = 0;
      let lock = 0;
      let i = 0;

      /*
       Container
       */

      // figure out the container height by getting the slide
      // with the longest text which is most likely to be the highest
      let c = 0;
      let $firstSlide = null;

      $slides.each(function(index, slide) {
        const $slide = $(slide);
        const slideLength = $slide.text().length;

        if (slideLength > c) {
          c = slideLength;
          $firstSlide = $slide;
        }
      });

      if ($firstSlide === null) {
        return;
      }

      // add the highest slide as a placeholder for the box model
      $container.
          prepend($($firstSlide).clone().css('visibility', 'hidden')).
          css(containerCSS);

      /*
       Slides
       */
      $slides.hide().css(slidesCSS).first().show();

      /*
       Touch functionality
       */
      if (touch) {
        $.Finger.motionThreshold = 25;

        $container.on('drag', function(e) {
          // fire only when the motion is horizontal
          if (e.orientation === 'horizontal') {
            e.stopPropagation();

            if (!lock) {
              // left to right, previous slide
              if (e.direction === 1 && e.end === true) {
                lock = 1;
                transition(-1);
              }

              // right to left, next slide
              if (e.direction === -1 && e.end === true) {
                lock = 1;
                transition();
              }
            }
          }
        });
      }

      /*
       Pager functionality
       */
      if ($pagerElement.length) {
        const pagerList = [];

        $slides.each(function(index) {
          const $item = $('<span>&bull;</span>').on('click', function() {
            if (!lock) {
              $(this).addClass('active').siblings().removeClass('active');
              lock = 1;
              transition(index);
            }

            return false;
          });

          pagerList.push($item);
        });

        pagerList[0].addClass('active');

        $pagerElement.prepend(pagerList);
      }

      /*
       Timeout string functionality
       */
      if (typeof timeout === 'string') {
        // parse the `timeout` parameter and generate a random timeout value
        const timeoutOptions = timeout.split('-');
        const timeoutMin = Math.ceil(timeoutOptions[1] || 5000);
        const timeoutMax = Math.floor(timeoutOptions[2] || 10000);

        timeout = Math.floor(Math.random() * (timeoutMax - timeoutMin + 1)) +
            timeoutMin;
      }

      /**
       * Transition
       *
       * @param {number} [nextNumber]
       */
      function transition(nextNumber) {
        // clear timeout before we do anything to avoid orphan timeouts
        clearTimeout(timeoutID);

        // animate only elements that are visible
        if ($container.is(':visible')) {
          // set direction, current and next slide
          let direction = 'forward';
          let $slideCurrent = $($slides[i]);
          let next = (i === (slidesCount - 1)) ? (0) : (i + 1);
          let $slideNext = $($slides[next]);

          // manual override
          if (typeof nextNumber === 'number') {
            // set direction
            direction = (nextNumber > i) ? (direction) : ('reverse');

            // set next
            next = nextNumber;

            // reverse one
            if (nextNumber === -1) {
              next = (i === 0) ? (slidesCount - 1) : (i - 1);
            }

            // set next slide object
            $slideNext = $($slides[next]);
          }

          // set next slide index
          i = next;

          // finish currently running animations
          $slides.velocity('finish', true);

          // make sure that we are not switching slides with the same index
          if ($slideCurrent[0] !== $slideNext[0]) {
            // fadeOutIn effect
            if (effect === 'fadeOutIn') {
              $slideCurrent.velocity('fadeOut', effectSpeed);
              $slideNext.velocity('fadeIn', effectSpeed);

              setTimeout(function() {
                lock = 0;
                timeoutID = setTimeout(transition, timeout);
              }, effectSpeed);
            }

            // slideOutThenIn effect
            if (effect === 'slideOutThenIn') {
              let firstEffect = 'transition.slideLeftOut';
              let secondEffect = 'transition.slideRightIn';

              if (direction === 'reverse') {
                firstEffect = 'transition.slideRightOut';
                secondEffect = 'transition.slideLeftIn';
              }

              $slideCurrent.velocity(
                  firstEffect,
                  effectSpeed * 0.5,
                  function() {
                    $slideNext.velocity(
                        secondEffect,
                        effectSpeed,
                        function() {
                          lock = 0;
                          timeoutID = setTimeout(transition, timeout);
                        });
                  });
            }

            // set current slide for pager
            if ($pagerElement.length) {
              $pagerElement.children().
                  removeClass('active').
                  eq(next).
                  addClass('active');
            }

            // finish
            return;
          }
        } // if ($container.is(':visible'))

        // container not visible or switching slides with the same index
        // release lock, set new timeout
        lock = 0;
        timeoutID = setTimeout(transition, timeout);
      }

      /*
       Initialize
       */
      timeoutID = setTimeout(transition, timeout);
    });
  };

  // jQuery 3+
  $(window).on('load', function() {
    $('.mcycle').mcycle();
  });
})(jQuery, window);
